<template>
  <div class="app-container">
    <el-row :gutter="20">
      <el-col :xs="24" :sm="24" :md="8" :lg="6" :xl="5" style="margin-bottom: 10px">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人信息</span>
          </div>
          <div>
            <div style="text-align: center">
              <el-upload :show-file-list="false" :on-success="handleSuccess" :on-error="handleError" :headers="headers" :action="updateAvatarApi" class="avatar-uploader">
                <img :src="user.avatar ? baseApi + '/avatar/' + user.avatar : Avatar" title="点击上传头像" class="avatar" />
              </el-upload>
            </div>
            <ul class="user-info">
              <li>
                <svg-icon icon-class="user1" />用户名称
                <div class="user-right">{{ user.username }}</div>
              </li>
              <li>
                <svg-icon icon-class="phone" />手机号码
                <div class="user-right">{{ user.phone }}</div>
              </li>
              <!-- <li><svg-icon icon-class="email" /> 用户邮箱 <div class="user-right">{{ user.email }}</div></li> -->
              <li v-if="user.erpCode">
                <svg-icon icon-class="dept" />Erp编码
                <div class="user-right">{{ user.erpCode }}</div>
              </li>
              <li>
                <svg-icon icon-class="date" />创建日期
                <div class="user-right">{{ parseTime(user.createTime) }}</div>
              </li>
              <li>
                <svg-icon icon-class="anq" />安全设置
                <div class="user-right">
                  <a @click="$refs.pass.dialog = true">修改密码</a>
                  <!-- <a @click="$refs.email.dialog = true">修改邮箱</a> -->
                </div>
              </li>
              <li>
                <div class="c h">
                  <el-input v-model="setWhiteList" style="width:200px;" clearable />&nbsp;
                  <el-button @click="handleSetWhiteList" :disabled="!setWhiteList">设置白名单</el-button>
                </div>
              </li>
            </ul>
          </div>
        </el-card>
      </el-col>
      <el-col :xs="24" :sm="24" :md="16" :lg="18" :xl="19">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>操作日志</span>
            <div style="display:inline-block;float: right;cursor: pointer" @click="refresh">
              <i :class="ico" />
            </div>
          </div>
          <div>
            <el-table v-loading="loading" :data="data" size="small" style="width: 100%;">
              <el-table-column prop="description" label="行为" />
              <el-table-column prop="requestIp" label="IP" />
              <!-- <el-table-column :show-overflow-tooltip="true" prop="address" label="IP来源"/> -->
              <!-- <el-table-column prop="time" label="请求耗时" align="center">
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.time <= 300">{{ scope.row.time }}ms</el-tag>
                  <el-tag v-else-if="scope.row.time <= 1000" type="warning">{{ scope.row.time }}ms</el-tag>
                  <el-tag v-else type="danger">{{ scope.row.time }}ms</el-tag>
                </template>
              </el-table-column>-->
              <el-table-column prop="createTime" label="创建日期" width="180px">
                <template slot-scope="scope">
                  <span>{{ parseTime(scope.row.createTime) }}</span>
                </template>
              </el-table-column>
            </el-table>
            <!--分页组件-->
            <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />
          </div>
        </el-card>
      </el-col>
    </el-row>
    <updateEmail ref="email" :email="user.email" />
    <updatePass ref="pass" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { regEmail } from "@/utils/index";
import updatePass from "./center/updatePass";
import updateEmail from "./center/updateEmail";
import { getToken } from "@/utils/auth";
import store from "@/store";
import { parseTime } from "@/utils/index";
import initData from "@/mixins/initData";
import Avatar from "@/assets/avatar/avatar.png";
import request from "@/utils/request";
export default {
  name: "Center",
  components: { updatePass, updateEmail },
  mixins: [initData],
  data() {
    return {
      setWhiteList: "",
      Avatar: Avatar,
      ico: "el-icon-refresh",
      headers: {
        Authorization: "Bearer " + getToken(),
      },
    };
  },
  computed: {
    ...mapGetters(["user", "updateAvatarApi", "baseApi"]),
  },
  created() {
    this.$nextTick(() => {
      this.init();
    });
    store.dispatch("GetInfo").then(() => {});
  },
  methods: {
    parseTime,
    formatEmail(mail) {
      return regEmail(mail);
    },
    beforeInit() {
      this.url = "api/logs/user";
      const sort = "id,desc";
      this.params = { page: this.page, size: this.size, sort: sort };
      return true;
    },
    handleSuccess(response, file, fileList) {
      this.$notify({
        title: "头像修改成功",
        type: "success",
        duration: 2500,
      });
      store.dispatch("GetInfo").then(() => {});
    },
    // 监听上传失败
    handleError(e, file, fileList) {
      const msg = JSON.parse(e.message);
      this.$notify({
        title: msg.message,
        type: "error",
        duration: 2500,
      });
    },
    refresh() {
      this.ico = "el-icon-loading";
      this.init();
      setTimeout(() => {
        this.ico = "el-icon-refresh";
      }, 300);
    },
    handleSetWhiteList() {
      request({
        url: "api/rdswl",
        method: "post",
        data: this.setWhiteList,
      })
        .then((res) => {
          if (res) {
            this.$notify({
              title: "添加白名单成功!",
              type: "success",
              duration: 2500,
            });
          } else {
            this.$notify({
              title: "添加白名单失败!",
              type: "error",
              duration: 2500,
            });
          }
        })
        .catch((err) => {
          console.log(err.response.data.message);
        });
    },
  },
};
</script>

<style rel="stylesheet/scss" lang="scss">
.avatar-uploader-icon {
  font-size: 28px;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
  border-radius: 50%;
}
.user-info {
  padding-left: 0px;
  list-style: none;
  li {
    border-bottom: 1px solid #f0f3f4;
    border-top: 1px solid #f0f3f4;
    padding: 11px 0px;
    font-size: 13px;
  }
  .user-right {
    float: right;

    a {
      color: #317ef3;
    }
  }
}
</style>
